import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, Stack, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import {PricingPlans, BillingDetails, PaymentMethodForm} from '../payment';
import { httpsCallable } from "firebase/functions";
import { ImagesDropzone } from "../image/imagesDropzone";
//import { SubscriptionContext } from "../payment/SubscriptionContext";
import { NavLink } from "react-router-dom";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

export const CreateGroup = () => {
    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    const [imageList, setImageList] = useState([]);
    const [previewImageFile, setPreviewImageFile] = React.useState('');

    const [loading, setLoading] = React.useState(false);
    const [groupName, setGroupName] = React.useState('');
    const [groupDescription, setGroupDescription] = React.useState('');

    useEffect(() => {
        imageList.forEach((image, index) => {
          //console.log('cover image', image);
          setPreviewImageFile(URL.createObjectURL(image.file));
        });
    }, [imageList]);

    const handleCreateGroupButtonClick = () => {
        setLoading(true);
        // create a group
        const createGroupCall = httpsCallable(functionsInstance, 'creategroup');
        createGroupCall({
            groupName: groupName,
            description: groupDescription
        }).then((res) => {
            //console.log('call res', res);
            if(res.data && res.data.groupId){
                setLoading(false); 
                if (imageList.length > 0) {
                    const storage = getStorage();
                    // Create a child reference
                    const imagesStorageRef = ref(storage, 'images/group-cover');
                    const image = imageList[0];
                    const uploadTask = uploadBytesResumable(ref(imagesStorageRef, res.data.slug), image.file);
                    uploadTask.on('state_changed',
                        (snapshot) => {
                          // Observe state change events such as progress, pause, and resume
                          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                          //console.log('Upload is ' + progress + '% done');
                          switch (snapshot.state) {
                            case 'paused':
                              //console.log('Upload is paused');
                              break;
                            case 'running':
                              //console.log('Upload is running');
                              break;
                          }
                        },
                        (error) => {
                          // Handle unsuccessful uploads
                        },
                        () => {
                            // Handle successful uploads on complete
                            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                //console.log('downloadURL', downloadURL, 'filename', res.data.slug);
                                updateGroupCoverImageURL(downloadURL, res.data.groupId, res.data.slug);
                            });
                        }
                    );
                } else {
                    navigate(config.pathnames.Group.replace(":groupSlug", res.data.slug));
                }
            }else{
                setError("Failed to create the group.");
                setLoading(false);                    
            }
        }).catch(err => {
            console.log('err', err);
            setError(err.message);
            setLoading(false);
        });
    }
    const updateGroupCoverImageURL = async (imgUrl, groupId, groupSlug) => {
        //console.log('updating group');
        const groupDoc = doc(firestoreInstance, 'groups', groupId);
        await setDoc(groupDoc, {
            coverImage: imgUrl
        },{merge: true});

        navigate(config.pathnames.Group.replace(":groupSlug", groupSlug));

    }
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} >
                <Grid size={{ xs: 12, md: 12 }}>
                    {error !== null &&
                        <Alert severity="error">{error}</Alert>
                    }
                    {prompt !== null &&
                        <Alert severity="info">
                            {prompt}
                        </Alert>
                    }
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={0}>
                            <Grid size={{xs: 12, md: 4}}>
                                <Grid container spacing={0}>
                                    <Grid size={12} sx={{width: '100%'}}>
                                        <Box sx={{p: 2}}>
                                        <TextField id="group-name" label="Name" variant="outlined" placeholder="name of the group" fullWidth onChange={(e) => {setGroupName(e.target.value)}} />
                                        </Box>
                                    </Grid>
                                    <Grid size={12} sx={{width: '100%'}}>
                                        <Box sx={{p: 2}}>
                                        <TextField id="group-description" label="Description" placeholder="about the group" rows={4} multiline fullWidth onChange={(e) => {setGroupDescription(e.target.value)}} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={{xs: 12, md: 8}}>
                                <Grid container spacing={0}>
                                    <Grid size={12} sx={{width: '100%'}}>
                                        <Box sx={{p: 2}} >
                                            <Box className='image-drag' sx={{borderRadius: 1,}}>
                                                <Box sx={{
                                                    position: 'absolute',
                                                    inset: 0,
                                                    borderRadius: 1,
                                                    overflow: 'hidden',
                                                    pointerEvents: 'none',
                                                    '& img': {
                                                    objectFit: 'cover',
                                                    objectPosition: 'center'
                                                    },
                                                }}>
                                                    {previewImageFile && <img src={previewImageFile} width="100%" height="100%" />}
                                                </Box>
                                                <Box sx={{borderRadius: 1, aspectRatio: '16 / 9'}}>
                                                    <ImagesDropzone setImageList={setImageList} previewFile={previewImageFile} placeholderText={`Upload Your Group Image<br />(required)<br /><div style= "font-size: 11px">Min. Dimensions (size): 1600 x 900<br />File format: PNG or JPG<br />File size: Less than 32MB</div>`} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={12} sx={{width: '100%'}}>
                                <Box sx={{p:2, display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                                    <LoadingButton
                                        onClick={handleCreateGroupButtonClick}
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<GroupIcon />}
                                        variant="outlined"
                                        disabled={!groupName.length>0}
                                    >
                                    Create Group
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            
            </Grid>
        </Box>
    )
}