import React, { useContext, useState, useEffect } from "react";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppContext } from "../../lib/AppProvider";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import GroupIcon from '@mui/icons-material/Group';
import WebIcon from '@mui/icons-material/Web';
import PaymentsIcon from '@mui/icons-material/Payments';
import Typography from '@mui/material/Typography';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { AuthContext } from "../../lib/Auth";

import { GroupMenuContext } from '../group/GroupMenuContext';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function SubMenu(props) {
  //console.log("SubMenu props ", props);
  const { group, isCreator } = useContext(GroupMenuContext);
  const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const { groupSlug } = useParams();
  const {config} = useContext(AppContext);
  const pathnames = config.pathnames;
  const groupHomeUrl = pathnames.Group.replace(":groupSlug", groupSlug);
  const groupSettingsUrl = pathnames.GroupSettings.replace(":groupSlug", groupSlug);
  const listUsersUrl = pathnames.GroupListUsers.replace(":groupSlug", groupSlug);
  const postUrl = null;
  const changePlanUrl = pathnames.GroupChangePlan.replace(":groupSlug", groupSlug);
  const listInvoicesUrl = pathnames.GroupInvoices.replace(":groupSlug", groupSlug);
  //console.log('group slug',groupSlug);

  return (
    <>
    {groupSlug && (<>
        <Button
          id="admin-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{ color: '#fff', textTransform: 'none' }}
        >
          Group {group.groupName}
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {groupHomeUrl && [
               <MenuItem onClick={
                   () => {
                       handleClose();
                       navigate(groupHomeUrl);
                   }} disableRipple>
                 <GridViewOutlinedIcon />
                 Home
               </MenuItem>
          ]}
          {(true) && (<>
          {postUrl && [
               <MenuItem onClick={
                   () => {
                       handleClose();
                       navigate(postUrl);
                   }} disableRipple>
                 <AutoStoriesOutlinedIcon />
                 Posts--
               </MenuItem>
          ]}
          </>)}
          {group.isGroupCreator && (
            <>
                <Divider textAlign="left" sx={{ my: 0.5 }} >
                    <Typography
                              color="text.secondary"
                              display="block"
                              variant="caption"
                            >
                              Manage
                            </Typography>
                </Divider>
                {listUsersUrl && [
                     <MenuItem onClick={
                         () => {
                             handleClose();
                             navigate(listUsersUrl);
                         }} disableRipple>
                       <GroupIcon />
                       Users
                     </MenuItem>
                ]}
                {changePlanUrl && [
                       <MenuItem onClick={
                           () => {
                               handleClose();
                               navigate(changePlanUrl);
                           }} disableRipple>
                         <WebIcon />
                         Change Plan
                       </MenuItem>
                ]}
                {listInvoicesUrl && [
                     <MenuItem onClick={
                         () => {
                             handleClose();
                             navigate(listInvoicesUrl);
                         }} disableRipple>
                       <PaymentsIcon />
                       Billing
                     </MenuItem>
                ]}
                {groupSettingsUrl && [
                   <MenuItem onClick={
                       () => {
                           handleClose();
                           navigate(groupSettingsUrl);
                       }} disableRipple>
                     <EditIcon />
                     Settings
                   </MenuItem>
                ]}
            </>
          )}
        </StyledMenu>
      </>)}
    </>
  );
}
