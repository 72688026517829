import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';

import {GroupPageContext} from "./GroupPageContext";

export const Group = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    console.log('currentGroup', currentGroup);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} 
                sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    },
                }}
            >
            <Grid size={{ xs: 12, md: 12 }}>
                {error !== null &&
                    <Alert severity="error">{error}</Alert>
                }
                {prompt !== null &&
                    <Alert severity="info">
                        {prompt}
                    </Alert>
                }
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
                <Typography>
                    {currentGroup.groupName}
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <Typography>
                    
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <Typography>
                    {currentGroup.description}
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
                <Typography>
                    <img src={currentGroup.coverImage} width='100%'/>
                </Typography>
            </Grid>
            </Grid>
        </Box>
    )
}