import React, { useContext, useState, useEffect } from "react";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { AppContext } from "../../lib/AppProvider";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import WebIcon from '@mui/icons-material/Web';
import PaymentsIcon from '@mui/icons-material/Payments';
import Divider from '@mui/material/Divider';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { AuthContext } from "../../lib/Auth";

import { GroupMenuContext } from '../group/GroupMenuContext';

export default function NestedList(props) {
  const { group, isCreator } = useContext(GroupMenuContext);
  const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const [openManageGroup, setOpenManageGroup] = React.useState(true);
  const { groupSlug } = useParams();
  const {config} = useContext(AppContext);
  const pathnames = config.pathnames;
  const groupHomeUrl = pathnames.Group.replace(":groupSlug", groupSlug);
  const groupSettingsUrl = pathnames.GroupSettings.replace(":groupSlug", groupSlug);
  const listUsersUrl = pathnames.GroupListUsers.replace(":groupSlug", groupSlug);
  const postUrl = null;
  const changePlanUrl = pathnames.GroupChangePlan.replace(":groupSlug", groupSlug);
  const listInvoicesUrl = pathnames.GroupInvoices.replace(":groupSlug", groupSlug);

  const handleClick = () => {
    setOpenManageGroup(!openManageGroup);
  };

  return (
    <>
    {groupSlug && (<>
      <Divider key="nested-list-group"/>
       <List
         sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
         component="nav"
         aria-labelledby="nested-list-subheader"
         subheader={
           <ListSubheader component="div" id="nested-list-subheader">
            Group {group.groupName}
           </ListSubheader>
         }
       >
          {groupHomeUrl && [
              <NavLink to={groupHomeUrl} style={{textDecoration:'none'}} key="group-home">
                  <ListItemButton>
                      <ListItemIcon><GridViewOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Home</Typography>} />
                  </ListItemButton>
              </NavLink>
          ]}
          {(true) && (<>
            {postUrl && [
              <NavLink to={postUrl} style={{textDecoration:'none'}} key="post">
                  <ListItemButton>
                      <ListItemIcon><AutoStoriesOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Posts</Typography>} />
                  </ListItemButton>
              </NavLink>
            ]}
          </>)}
          {group.isGroupCreator && (<>
            <ListItemButton onClick={(e)=>{
                e.stopPropagation();
                handleClick();
              }}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Manage" />
              {openManageGroup ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openManageGroup} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {listUsersUrl && [
                  <NavLink to={listUsersUrl} style={{textDecoration:'none'}} key="listusers">
                   <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon><GroupIcon /></ListItemIcon>
                          <ListItemText primary={<Typography color="textPrimary">Users</Typography>} />
                   </ListItemButton>
                  </NavLink>
                ]}
                {changePlanUrl && [
                     <NavLink to={changePlanUrl} style={{textDecoration:'none'}} key="plans">
                      <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon><WebIcon /></ListItemIcon>
                            <ListItemText primary={<Typography color="textPrimary">Change Plan</Typography>} />
                        </ListItemButton>
                     </NavLink>
                ]}
                {listInvoicesUrl && [
                   <NavLink to={listInvoicesUrl} style={{textDecoration:'none'}} key="billing">
                      <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon><PaymentsIcon /></ListItemIcon>
                          <ListItemText primary={<Typography color="textPrimary">Billing</Typography>} />
                      </ListItemButton>
                   </NavLink>
                ]}
                {groupSettingsUrl && [
                  <NavLink to={groupSettingsUrl} style={{textDecoration:'none'}} key="settings">
                     <ListItemButton sx={{ pl: 4 }}>
                         <ListItemIcon><EditIcon /></ListItemIcon>
                         <ListItemText primary={<Typography color="textPrimary">Settings</Typography>} />
                     </ListItemButton>
                  </NavLink>
                ]}
              </List>
            </Collapse>
          </>)}
       </List>
      </>)}
    </>
  );
}
