import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';

import {GroupPageContext} from "./GroupPageContext";

export const GroupSettings = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    console.log('currentGroup', currentGroup);

    return (
        <>
            {currentGroup.isGroupCreator ? (<>
                {currentGroup.groupName} Settings
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}