import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import { AuthContext } from "../../lib/Auth";
import { Alert, Box, Container } from "@mui/material";

export const GroupMenuContext = React.createContext();

export const GroupMenuProvider = ({children}) => {
    const { groupSlug } = useParams();
    const [ group, setGroup ] = useState(null);
    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(AppContext);

    useEffect(() => {
        if (typeof groupSlug !== 'undefined') {
            //console.log('menu groupSlug', groupSlug);
            const groupRef = collection(firestoreInstance, '/groups');
            const q = authUser.user ? query(groupRef, and(where('slug', '==', groupSlug), or(where('public', '==', true), where('creator', '==', authUser.user.uid)))) : query(groupRef, and(where('slug', '==', groupSlug), where('public', '==', true)));
            getDocs(q).then(snapshots => {
                if (snapshots.empty) {
                    //setLoading(false);
                } else {
                    snapshots.forEach(group => {
                        //console.log('group ID', group.id, 'group data', group.data());
                        setGroup({
                            id:group.id, isGroupCreator: false, ...group.data()
                        });

                        if (authUser.user && authUser.user.uid) {
                            const groupData = group.data();
                            const creatorId = groupData.creator;
                            const userDocRef = doc(firestoreInstance, "users", creatorId);
    
                            if (creatorId == authUser.user.uid) {
                                getDoc(userDocRef).then(docSnap => {
                                    const user = docSnap.data();
                                    //console.log('user', user);
                                    setGroup(prevState => ({
                                        ...prevState,
                                        isGroupCreator: true
                                    }));
                                    //setLoading(false);
                                }).catch(error => {
                                    //setLoading(false);
                                    console.log('error', error);
                                });
                            }
                        }
                    });
                }
            }).catch(error => {
                console.log('query error', error);
            });
        }
    }, [groupSlug, firestoreInstance]);

    return (
        <>
            {group !== null && (
                <GroupMenuContext.Provider value={{group, setGroup}}>
                    {children}
                </GroupMenuContext.Provider>
            )}
        </>
    )

}