import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, Stack, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import {PricingPlans, BillingDetails, PaymentMethodForm} from '../payment';
import { httpsCallable } from "firebase/functions";

import {GroupPageContext} from "./GroupPageContext";

export const GroupChangePlan = () => {

    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    const currentSubscriptionId = currentGroup.subscriptionId;

    const [ processing, setProcessing ] = useState(false);
    const [ selectedPlan, setSelectedPlan ] = useState(null);
    const [ paymentStep, setPaymentStep ] = useState(1);
    const [ showPaymentMethod, setShowPaymentMethod ] = useState(false);
    const [ billingDetails, setBillingDetails ] = useState(null);
    const [currentPlanId, setCurrentPlanId] = useState(null);
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ success, setSuccess ] = useState(false);
    const [subscription, setSubscription] = React.useState(null);

    const selectPlan = (plan) => {
        setProcessing(true);
        setError(null);
        if (plan.free || currentPaymentMethod) {
            // subscribe to the new plan
            const changeSubscriptionPlan = httpsCallable(functionsInstance, 'changesubscriptionplan');
            changeSubscriptionPlan({
                paymentMethodId: currentPaymentMethod,
                billingDetails: null,
                planId: plan.id,
                subscriptionId: currentSubscriptionId
            }).then(() => {
                setSubscription(prevState => ({
                    ...prevState,
                    plan: plan.title, // title of the plan
                    planId: plan.id, // price ID in stripe
                    paymentCycle: plan.frequency,
                    price: plan.price,
                    currency: plan.currency
                }));
                setCurrentPlanId(plan.id);
                setSuccess(true);
                setProcessing(false);
            }).catch(err => {
                setError(err.message);
                setProcessing(false);
            });
        } else {
            //the group is default to free plan
            // show payment method
            setSelectedPlan(plan);
            setShowPaymentMethod(true);
            setProcessing(false);
        }
    }

    const submitPlan = (paymentMethod) => {
        setProcessing(true);
        setError(null);
        console.log('paymentMethod', paymentMethod);
        const changeSubscriptionPlan = httpsCallable(functionsInstance, 'changesubscriptionplan');
        changeSubscriptionPlan({
            paymentMethodId: paymentMethod.id,
            billingDetails: billingDetails,
            planId: selectedPlan.id,
            subscriptionId: currentSubscriptionId
        }).then(() => {
            const pmRef = doc(firestoreInstance, 'users/'+authInstance.currentUser.uid+'/paymentMethods/'+paymentMethod.id);
            return setDoc(pmRef,{
                type: paymentMethod.type,
                cardBrand: paymentMethod.card.brand,
                cardExpMonth: paymentMethod.card.exp_month,
                cardExpYear: paymentMethod.card.exp_year,
                cardLast4: paymentMethod.card.last4
            },{merge:true});
        }).then(() => {
            setSubscription(prevState => ({
                ...prevState,
                plan: selectedPlan.title, // title of the plan
                planId: selectedPlan.id, // price ID in stripe
                paymentCycle: selectedPlan.frequency,
                price: selectedPlan.price,
                currency: selectedPlan.currency,
                paymentMethod: paymentMethod.id
            }));
            setPrompt('Your subscription plan has been changed.');
            setProcessing(false);
        }).catch(err => {
            setError(err.message);
            setProcessing(false);
        });
    }

    useEffect(() => {
        const subRef = doc(firestoreInstance, "subscriptions", currentSubscriptionId);
        getDoc(subRef).then(docSnap => {
            const sub = docSnap.data();
            setCurrentPlanId(sub.planId);
            setCurrentPaymentMethod(sub.paymentMethod);
        }).catch(error => {
            console.log('error', error);
        });
    }, [groupSlug, currentSubscriptionId]);

    return (
        <>
            {currentGroup.isGroupCreator ? (<>
                {success &&
                    <Alert severity="success">Your subscription plan has been changed.</Alert>
                }
                <Box>{currentGroup.groupName} Plans</Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                    {showPaymentMethod ? (
                        <>
                        <Stack spacing={3}>
                            { paymentStep === 1 && 
                                <>
                                    <Typography
                                    component="h1"
                                    variant="h3"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                    >
                                    Your Billing Details
                                    </Typography>
                                    {error !== null && 
                                        <Alert severity="error">{error}</Alert>
                                    }
                                    <BillingDetails buttonText={"Continue"} setBillingDetailsObject={(obj) => {
                                            setBillingDetails(obj);
                                            setPaymentStep(2);
                                        }
                                    } />
                                </>
                            }
                            { paymentStep === 2 && 
                                <>
                                    <Typography
                                    component="h1"
                                    variant="h3"
                                    align="center"
                                    color="text.primary"
                                    gutterBottom
                                    >
                                    Setup Payment Method
                                    </Typography>
                                    {error !== null && 
                                        <Alert severity="error">{error}</Alert>
                                    }
                                    <PaymentMethodForm buttonText={"Subscribe"} setPaymentMethod={submitPlan} disabled={processing} />
                                </>
                                
                            }
                        </Stack>
                        </>
                    ) : (<>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <PricingPlans selectPlan={selectPlan} disabled={processing} selectedPlanId={currentPlanId} />
                        </Grid>
                    </>)}
                    </Grid>
                </Box>
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}